/***
*
*   BILLING
*   Change a subscription plan or update card details
*
**********/

import React, { useContext } from 'react';
import { AuthContext, Card, AccountNav,
  Message, Form, useAPI, usePlans } from 'components/lib';
import Style from "../../../components/card/card.module.scss";

const Messages = require('./messages.json');

export function Billing(props){

  // state/context
  const context = useContext(AuthContext);

  // fetch plans and subscription
  const plans = usePlans();
  const subscription = useAPI('/api/account/subscription');

  if (!context.permission?.owner) {
    window.location = '/account';
    return null;
  }

  return (
    <main className="container">
      <AccountNav/>
      <div style={{display: 'flex'}}>
        { !subscription?.loading && (subscription?.data !== 'active' && subscription?.data !== 'trialing') &&
          <Message { ...Messages[subscription.data] } />
        }
          <Card
              loading={ plans.loading }
              title='Plan'
              className={Style.accountCards}
              shadow
          >
            <Form
              data={{
                plan: {
                  label: 'Your plan',
                  type: 'select',
                  required: true,
                  default: plans?.data?.active,
                  options: plans?.data?.list
                }
              }}
              url='/api/account/plan'
              method='PATCH'
              buttonText='Change plan'
              callback={(res) => {
                context.update({ plan: res.data.data.plan });
              }}
            />
          </Card>
      </div>
    </main>
  );
}
