/***
*
*   MASTER DASHBOARD
*   Manage all users signed up to your application
*
**********/

import React, { useContext } from 'react';
import Axios from 'axios';
import { ViewContext, AuthContext, Card, Table, Animate, usePermissions, useAPI } from 'components/lib';

export function MasterUsers(props){

  const context = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const permissions = usePermissions();
  const users = useAPI('/api/master/users');

  function editUser(data, callback){

    // only master can change owner permission
    if (permissions.data.list.findIndex(x => x.value === 'owner') < 0)
      permissions.data.list.push({ value: 'owner', label: 'owner' });

    context.modal.show({

      title: 'Edit User',
      form: {
        id: {
          type: 'hidden',
          value: data.id,
        },
        account_id: {
          type: 'hidden',
          value: data.account_id
        },
        name: {
          label: 'Name',
          type: 'text',
          value: data.name,
          requried: true,
        },
        email: {
          label: 'Email',
          type: 'email',
          value: data.email,
          required: true
        },
        permission: {
          label: 'Permission',
          type: 'select',
          default: data.permission,
          options: permissions.data.list,
          required: true,
        }
      },
      buttonText: 'Save',
      url: '/api/master/user',
      method: 'PATCH'

    }, (res) => {

      context.notification.show(data.name + ' was updated', 'success', true);
      callback(res);

    });
  }

  function deleteUser(data, callback){

    context.modal.show({

      title: 'Delete User',
      form: {
        id: {
          type: 'hidden',
          value: data.id
        },
        account: {
          type: 'hidden',
          value: data.account_id
        }
      },
      buttonText: 'Delete User',
      text: 'Are you sure you want to delete ' + data.name + '?',
      url: '/api/master/user',
      method: 'DELETE'

    }, () => {

      context.notification.show(data.name + ' was deleted', 'success', true);
      callback();

    });
  }

  async function impersonateUser(data){

    try {

      const res = await Axios.post('/api/master/user/impersonate', { id: data.id });

      if (res.status === 200)
        authContext.signin(res);

    }
    catch (err){

      context.handleError(err);

    }
  }

  return(

    <Animate>
      <Card>
        <Table
          search
          data={ users.data }
          loading={ users.loading }
          hide={['id', 'account_id']}
          badge={{ col: 'permission', color: 'blue' }}
          actions={{
            
            edit: editUser, 
            delete: deleteUser, 
            email: true,
            custom: [{ icon: 'log-in', action: impersonateUser }]

          }}
        />
      </Card>
    </Animate>
  );
}
