/**
 * The layout for Ratency frontpage
 */
import React, { Fragment } from 'react';
import {Header} from './header/header';
import './layout.scss';

export function Layout(props) {
    const user = JSON.parse(localStorage.getItem('user'));
    return (
        <Fragment>
            <Header user={user}/>
            { <props.children {...props.data} user={user}/> }
        </Fragment>
    )
}
