import React from 'react';

const ArrowDown = ({style}) => (
    <React.Fragment>
        <svg style={{cursor: 'pointer', width: '24px', height: '24px', fill: '#898989', ...style}}>
            <path d="M0,0H24V24H0Z" fill="none"/>
            <path d="M7,10l5,5,5-5Z"/>
        </svg>
    </React.Fragment>
);

export default ArrowDown;
