import React from 'react';
import './ratencyFooter.scss';
import ConditionalWrapper from '../hoc/ConditionalWrapper';

const RatencyFooter = () => {
    const date = new Date();
    const isResultPage = window.location.pathname.indexOf('result') !== -1;

    return (
        <div className="footer-container">
            <div className="container">
                {
                    isResultPage &&
                    <strong>Ratency.com</strong>
                }
                <ConditionalWrapper condition={isResultPage} wrapper={(children) => <div className="footer-text">{children}</div>}>
                    <div className="row">
                        <div className={isResultPage ? 'col-12' : 'col-6' }>
                            ©{date.getFullYear()}  VroomVroomVroom Pty Ltd. ABN 19 050 417 037 {
                            isResultPage
                                ? 'owns all copyright in the database, the compilation and presentation of this data and report. This report and its data are confidential to Vroom Vroom Vroom Pty Ltd. The Ratency user who has downloaded this report may use this report for the purpose of settling credit hire claims or actuary services only. You are not authorized to copy, exploit or otherwise use or disclose this report or data for any other use.'
                                : ''
                            }
                        </div>
                        {
                            !isResultPage &&
                            <div className="col-6 footer-links font-bold">
                                <a href="https://www.vroomvroomvroom.com.au/privacy-policy/" target="_blank">Privacy Policy</a>
                            </div>
                        }
                    </div>
                </ConditionalWrapper>
            </div>
        </div>
    )
};

export default RatencyFooter;
