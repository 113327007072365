import React, { Fragment, useState } from 'react';
import { Form, Message} from 'components/lib';

export function ContactForm(props){

  const [sent, setSent] = useState(false);

  return (
    <Fragment>

      { sent ?
        <Message
          title='Message Sent'
          type='success'
          text={ `Thank you for your message, we'll be in touch as soon as possible.` }
        />  :

        <Form
          data={{
            name: {
              label: 'Name',
              type: 'text',
              required: true,
              errorMessage: 'Please enter your name'
            },
            company: {
              label: 'Company',
              type: 'text',
              required: true,
              errorMessage: 'Please enter your company'
            },
            email: {
              label: 'Your email',
              type: 'email',
              required: true,
              errorMessage: 'Please enter your email address',
              wrapperClassName: 'input-inline'
            },
            phone: {
              label: 'Phone number',
              type: 'phone',
              required: true,
              errorMessage: 'Please enter your phone',
              wrapperClassName: 'input-inline last'
            },
            message: {
              label: 'Message',
              type: 'textarea',
              required: true,
              errorMessage: 'Please enter a message'
            }
          }}
          method='POST'
          url='/api/utility/mail'
          callback={ e => setSent(true) }
          buttonText='Send'
          fullWidthButton={false}
         />
       }
     </Fragment>
  );
}
