/***
*
*   CONTACT FORM
*   Will send an email notification to you
*
**********/

import React from 'react';
import { ContactForm } from 'components/lib';
import './contact.scss';

export function Contact(props){

  return(
      <div className="contact-page">
          <div className="container">
              <div className="row">
                  <div className="col-xs-12 col-sm-5">
                      <h1>We’d love to hear from you</h1>
                      <p>
                          We’d love to hear from you. If you have any questions or request for custom reporting solutions, please don’t hesitate in contacting us.
                      </p>
                      <div className="list">
                          <span className={`material-icons-outlined md-blue-circle md-40-circle`}>email</span>
                          <strong>
                              <a href="mailto:enquiries@ratency.com">enquiries@ratency.com</a>
                          </strong>
                      </div>
                  </div>
                  <div className="col-xs-12 col-sm-5 offset-sm-2">
                      <div className="form-container">
                          <h3>Contact us</h3>
                          <ContactForm />
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}
