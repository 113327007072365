/***
*
*   ACCOUNT NAV
*   Acount sub nav that renders different options based
*   on user permissions. Shows billing & user admin to only account owners
*
**********/

import React, { useContext } from 'react';
import { NavLink } from "react-router-dom";
import { AuthContext } from 'components/lib';
import './sub.scss';

export function AccountNav(){

  const context = useContext(AuthContext);

  return(
    <nav className='subnav'>
      { context.permission?.master && <NavLink exact to='/address' activeClassName='active'>Address</NavLink> }
      { context.permission?.master && <NavLink exact to='/depot' activeClassName='active'>Depots</NavLink> }

      { context.permission?.admin &&
        <NavLink exact to='/account/team' activeClassName='active'>My team</NavLink> }
      <NavLink exact to='/account' activeClassName='active'>Account settings</NavLink>
      { context.permission?.owner &&
        <NavLink exact to='/account/billing' activeClassName='active'>Billing</NavLink> }
    </nav>
  );
}
