import { Profile } from 'views/account/profile';
import { Billing } from 'views/account/billing';
import { Users } from 'views/account/users';

const Routes = [
  {
    path: '/account',
    view: Profile,
    layout: 'ratency',
    permission: 'user',
    title: 'Your Profile'
  },
  {
    path: '/account/billing',
    view: Billing,
    layout: 'ratency',
    permission: 'owner',
    title: 'Billing'
  },
  {
    path: '/account/team',
    view: Users,
    layout: 'ratency',
    permission: 'admin',
    title: 'Users'
  },
]

export default Routes;
