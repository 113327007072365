/***
*
*   USERS
*   Enables an admin to manage the users in their application
*
**********/

import React, { useContext } from 'react';
import { ViewContext, AccountNav, Table,
  Button, Animate, useAPI, usePermissions } from 'components/lib';
import './users.scss';
import Axios from 'axios';
import usersTableDataTransformer from '../../Utils/usersTableDataTransformer';
import { format } from 'date-fns';

export function Users(props){

  const context = useContext(ViewContext);
  const permissions = usePermissions();
  const users = useAPI('/api/account/users');
  const invites = useAPI('/api/account/invites');

  function invite(data){
    let invitedUserEmail = '';
    context.modal.show({
      title: 'Add user',
      form: {
        email: {
          label: 'Email',
          type: 'text',
          placeholder: 'Enter email/s',
          required: true,
        }
      },
      buttonText: 'Send invite',
      text: 'To invite more than one user, seperate the emails with a comma.',
      url: '/api/invite',
      method: 'POST',
      customValidator: (data) => {
          const emails = data.email.value.split(',');
          const rgx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          let isValid = true;
          let errorMessage = '';
          emails.forEach((email) => {
              if(!rgx.test(email)) {
                isValid = false;
                errorMessage = 'Please enter valid email addresses';
              }
              
              if (users.data && users.data.findIndex(user => user.email.toLocaleLowerCase() === email.toLocaleLowerCase()) > -1) {
                isValid = false;
                errorMessage = 'This user is already registered';
              } 
              else if (invites.data !== null && invites.data.findIndex(user => user.email.toLocaleLowerCase() === email.toLocaleLowerCase()) > -1) {
                isValid = false;
                errorMessage = 'This user is already invited';
              }
          });
          
          const updatedData = Object.keys(data).map((key) => {
            data[key].valid = isValid;

            if (isValid === false) {
              data[key].errorMessage = errorMessage;
            }

            data[key].value = data[key].value.toLowerCase();

            return data[key];
          });
          
          if (isValid === false) {
            return false;
          }

          return {
            isValid,
            updatedData
          };
      },
      responseResult: (res) => {
        if (res.status === 200) {
          const invitedUser = {
            id: res.data.data,
            date_sent: format(new Date(), 'dd MMM yyyy'),
            email: invitedUserEmail
          }
          invites.data.push(invitedUser);
        }
      }
    }, (res) => {
      invitedUserEmail = res.email.value;
    });
  }

  function editUser(data, callback){

    context.modal.show({
      title: 'Update User',
      form: {
        id: {
          type: 'hidden',
          value: data.id
        },
        name: {
          label: 'Name',
          type: 'text',
          required: true,
          value: data.name,
          errorMessage: 'Please enter a name'
        },
        email: {
          label: 'Email',
          type: 'email',
          value: data.email,
          required: true,
        },
        permission: {
          label: 'Permission',
          type: 'select',
          options: permissions.data.list,
          default: data.permission
        }
      },
      buttonText: 'Save',
      url: '/api/user',
      method: 'PATCH'

    }, (res) => {
      context.notification.show(data.name + ' was updated', 'success', true);
      
      users.data.map((user) => {
        if (res.id.value === user.id) {
          user.name = res.name.value;
          user.email = res.email.value;
          user.permission = res.permission.value;
        }
      });

      callback();

    });
  }

  function deleteUser(data, callback){
    const isUser = data.permission || data.onboarded;
    context.modal.show({
      title: 'Delete User',
      form: {
        id: {
          type: 'hidden',
          value: data.id
        }
      },
      buttonText: 'Delete User',
      text: 'Are you sure you want to delete ' + data.name + '?',
      url: `/api/${isUser ? 'user' : 'invite'}`,
      method: 'DELETE'

    }, () => {
      if (isUser) {
        users.data = users.data.filter(user => user.id !== data.id);
      } else {
        invites.data = invites.data.filter(user => user.id !== data.id);
      }
      context.notification.show(`${isUser ? data.name : data.email} was deleted`, 'success', true);
      callback();

    });
  }
  
  async function resendInvitation (event, data) {
    if (event.target.classList.contains('loading')) {
      return;
    }
    event.target.classList.add('loading');

    try {
      const res = await Axios.patch(`/api/invite/${data.id}/resend`);
      event.target.classList.remove('loading');
      res.status === 200  
        ? context.notification.show(`Invite to ${data.email} was resent`, 'success', true)
        : context.notification.show(`Error resending invite to ${data.email}`, 'error', true);
    }
    catch (err){
      event.target.classList.remove('loading');
      context.notification.show(`Error resending invite to ${data.email}`, 'error', true);
    }
  }

  return (
    <main className="container my-team-container">
      <AccountNav />
      <Animate>
        <section className='title-row'>
          <h1>My team</h1>
          <Button
              small
              text='Add user'
              icon="plus"
              size={ 16 }
              fillIcon
              color='default'
              action={ invite }
          />
        </section>
        <Table
          search
          className='restrict-width'
          contentFormatter={{
            'permission': (value) => {
               if (value.toLowerCase() === 'master' || value.toLowerCase() === 'owner') {
                 return (<strong className="master-account">{value}</strong>)
               } else if (value.toLowerCase() === 'admin') {
                 return (<strong className="admin-account">{value}</strong>)
               } else {
                 return (<strong className="guest-account">{value}</strong>)
               }
            },
            'user': (value) => {
              return (
                  <div className='flex items-center'>
                    <div>
                      <strong>{value[1]}</strong>
                      <div className='font-normal'>{value[0]}</div>
                    </div>
                    { value[2] ? <div className='invited-label text-xsm rounded-md font-bold'>INVITED</div> : null }
                  </div>
              )
            },
            'custom_actions': (value) => {
              return (
                  <div className='flex items-center justify-end'>
                    { 
                      typeof value.id !== 'undefined' 
                      && <Button action={ () => deleteUser(value) } textOnly text="Delete" className="font-bold delete-button text-underline" /> 
                    }
                    { 
                      typeof value.name !== 'undefined' && typeof value.id !== 'undefined'
                      ? <Button action={ () => editUser(value) } textOnly text="Edit" className="font-bold edit-button text-underline" />
                      : typeof value.id !== 'undefined' && <button onClick={ (event) => resendInvitation(event, value) } className="font-bold resend-button text-underline">Resend invite</button>
                    }
                  </div>
              )
            }
          }}
          data={ usersTableDataTransformer(users, invites) }
          loading={ users?.loading || invites?.loading }
          show={['user', 'Date created', 'permission', 'custom_actions']}
        />
      </Animate>
    </main>
  );
}
