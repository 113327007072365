/***
*
*   FORGOT PASSWORD
*   Trigger a password reset process
*
**********/

import React from 'react';
import { Animate, Form, Card } from 'components/lib';
import RatencyHeader from "../../components/ratencyHeader/ratencyHeader";
import Style from "./auth.module.scss";

export function ForgotPassword(props){

  return(
    <div className={Style.page_white_background}>
      <RatencyHeader/>
      <div className="container">
        <Animate type='pop'>
          <div className={Style.header}>Reset Your Password</div>
            <Card noPadding className={Style.card_container}>

              <p>Enter your email address and we'll send you
              instructions to reset your password.</p>

              <Form
                data={{
                  email: {
                    label: 'Email',
                    type: 'email',
                    placeholder: 'Your email',
                    required: true
                  }
                }}
                url='/api/user/password/reset/request'
                method='POST'
                buttonText='Reset Password'
              />

            </Card>
        </Animate>
      </div>
    </div>
  );
}
