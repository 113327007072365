/***
*
*   LOADER
*   Infinite spinning animation for loading states
*
**********/

import React from 'react';
import ClassNames from 'classnames';
import Center from './images/center.svg';
import Orbit from './images/orbit.svg';
import Style from './loader.module.scss';

export function Loader(props){

  const css = ClassNames([

    Style.loader,
    props.className

  ]);

  return (
    <div className={ css }>
      <img src={ Center } className={ Style.center } alt='Orbit Center'/>
      <img src={ Orbit } className={ Style.orbit } alt='Orbit Spinner'/>
    </div>

  );
}
