import HistoryPage from '../historypage/index.js';

const Routes = [
    {
        path: '/history',
        view: HistoryPage,
        layout: 'history',
        permission: 'master',
        title: 'Searches history'
    },
];

export default Routes;
