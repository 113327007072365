import React from 'react';
import Style from "./input.module.scss";
import {Label} from "../label/label";

export function DisabledInput(props){

    return(
        <div className={ Style.input }>
            <Label
                text={ props.label }
                required={ props.required }
                for={ props.name }
            />
            <input
                type='text'
                disabled
                className={Style.textbox}
                id={ props.name }
                name={ props.name }
                value={ props.value || '' }
            />
        </div>
    );
}
