import {List} from "../views/depot/list";

const Routes = [
    {
        path: '/depot',
        view: List,
        layout: 'ratency',
        permission: 'master',
        title: 'Manage Depot'
    }
];

export default Routes;
