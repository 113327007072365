import React from 'react';

const BlueSquiggle = () => (
    <React.Fragment>
        <defs><clipPath id="a"><rect width="1366" height="768" fill="#fff"/>
        </clipPath><linearGradient id="b" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stopColor="#d0e3f8"/><stop offset="1" stopColor="#f5f6fa"/></linearGradient></defs><g clipPath="url(#a)"><path d="M6023.126,5027.362s-299.345,35.394-347.881,181.7,25.016,347.085-155.11,420.826-210.521,147.483-208.7,238.78,69.589,120.267,69.589,120.267l832.518-42.46,32.009-127.1-70.6-780.628Z" transform="translate(-3979.334 -5872.374) rotate(8)" fill="url(#b)"/></g>
    </React.Fragment>
)

export default BlueSquiggle;
