import React from 'react';
import Axios from 'axios';

// components
import { Router, Route, Switch } from 'react-router-dom';
import { PrivateRoute, AuthProvider } from './auth';
import { History, View } from 'components/lib';

// 404
import { NotFound } from 'views/error/404';

// settings
const Settings = require('settings.json');

const Routes = [
  ...require('routes/account').default,
  ...require('routes/address').default,
  ...require('routes/app').default,
  ...require('routes/auth').default,
  ...require('routes/website').default,
  ...require('routes/missioncontrol').default,
  ...require('routes/frontpage').default,
  ...require('routes/history').default,
  ...require('routes/ratency').default,
  ...require('routes/depot').default,
]

export default function App(){

  const user = JSON.parse(localStorage.getItem('user'));

  if (user?.token){

    // add auth token to api header calls & log last_active
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
    Axios.patch(
        '/api/user/',
        { last_active: new Date().toISOString().slice(0, 19).replace('T', ' ') }
        )
        .catch(() => {
          localStorage.removeItem('user');
          History.replace('/signin');
        });

  }

  // render the routes
  return(
      <AuthProvider>
        <Router history={ History }>
          <Switch>
            { Routes.map(route => {

              const RouteType = route.permission ? PrivateRoute : Route;

              return (
                <RouteType key={ route.path } exact path={ route.path }
                  permission={ route.permission } render={(data) =>
                  <View display={ route.view } layout={ route.layout }
                    title={ route.title } data={ data }
                  />}
                />
              );
            })}

            { /* 404 */}
            <Route render={() => <View display={ NotFound }
              layout='not_found' title='404 Not Found' />}/>

          </Switch>
        </Router>
      </AuthProvider>
  );
}
