import {List} from "../views/address/list";

const Routes = [
    {
        path: '/address',
        view: List,
        layout: 'ratency',
        permission: 'master',
        title: 'Manage Address'
    }
];

export default Routes;
