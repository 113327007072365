import { format } from 'date-fns';

export default function (users, invites) {
    if (typeof users === 'undefined' || (users.data === null || typeof users.data === 'undefined')) {
        users.data = [];
    }

    if (typeof invites === 'undefined' || (invites.data === null || typeof invites.data === 'undefined')) {
        invites.data = [];
    }

    const listWithInvititedUsers = users.data.concat(invites.data);

    return listWithInvititedUsers.map((data) => {
        data.date_created = data.date_sent ? format(new Date(data.date_sent), 'dd MMM yyyy') : format(new Date(data.date_created), 'dd MMM yyyy');
        const newData = {
            user: typeof data.name === 'string' ? [data.email, data.name] : [data.email, data.email, 'invited'],
            ...data,
            permission: data.permission ? data.permission : 'user',
            'Date created': data.date_created,
            custom_actions: data
        }

        delete newData.date_created;

        return newData;
    });
}
