import React, {useContext, useEffect, useState} from 'react';
import {AccountNav} from "../../components/nav/sub/account";
import {Animate} from "../../components/animate/animate";
import queryString from "query-string";
import ClassNames from "classnames";
import Style from "../../components/form/form.module.scss";
import {TextInput} from "../../components/form/input/text";
import {Button} from "../../components/button/button";
import {ViewContext} from "../../components/view/view";
import {useAPI} from "../../components/hooks/api";
import TableStyle from '../../components/table/table.module.scss';

let context;

function handleSubmitForm(formData) {
    const query = queryString.parse(window.location.search);

    for (const [key, value] of Object.entries(formData)) {
        query[key] = value;
    }

    // reset offset to 0 when searching
    query.offset = 0;
    window.location = window.location.origin + '/address?' + queryString.stringify(query);
}

function SearchForm() {
    const [formData, setFormData] = useState(queryString.parse(window.location.search));

    const css = ClassNames([
        Style.form
    ])

    return (
        <form className={ css } method="GET">
            <TextInput name="id" label="Search by ID"
                       onChange={(input, value) => setFormData({id: value})} value={formData.id} />
            <Button text="Search" action={() => handleSubmitForm(formData)} />
        </form>
    )
}

/** redirect page based on pagination */
function pagination(filter, action) {
    switch (action) {
        case 'prev':
            filter.offset = parseInt(filter.offset) - parseInt(filter.limit);

            if (filter.offset < 0) {
                filter.offset = 0;
            }
            break;
        case 'next':
        default:
            filter.offset = parseInt(filter.offset) + parseInt(filter.limit);
            break;
    }

    window.location = window.location.origin + '/address?' + queryString.stringify(filter);
}

export function List() {
    context = useContext(ViewContext);
    const query = queryString.parse(window.location.search);
    const [addresses, setAddresses] = useState(null);
    const filter = {
        offset: 0,
        limit: 50
    };

    for (const [key, value] of Object.entries(query)) {
        filter[key] = value;
    }

    const {data, loading} = useAPI('/api/address?' + queryString.stringify(filter));

    useEffect(() => {
        if (data !== null && loading === false) {
            setAddresses(data);
        }
    }, [data, loading]);

    const update = (data) => {
        context.modal.show(
            {
                title: 'Update',
                form: {
                    address: {
                        label: 'Address',
                        type: 'text',
                        required: true,
                        value: data.address,
                        errorMessage: 'Address are required'
                    },
                    x: {
                        label: 'Longitude',
                        type: 'number',
                        required: true,
                        value: data.coordinates.x,
                        errorMessage: 'Please enter correct coordinate'
                    },
                    y: {
                        label: 'Latitude',
                        type: 'number',
                        value: data.coordinates.y,
                        required: true,
                        errorMessage: 'Please enter correct coordinate'
                    }
                },
                buttonText: 'Save',
                url: '/api/address/' + data.id,
                method: 'PATCH'
            },
            (res) => {
                context.notification.show('Address ' + data.id + ' was updated', 'success', true);
                const updatedAddresses = addresses.map((addr) => {
                    if (addr.id === data.id) {
                        addr.address = res.address.value;
                        addr.coordinates.x = parseFloat(res.x.value);
                        addr.coordinates.y = parseFloat(res.y.value);
                    }
                    return addr;
                });

                setAddresses(updatedAddresses);
            }
        )
    }

    return (
        <main className="container">
            <AccountNav />
            <Animate>
                <section className='title-row'>
                    <h1>Address</h1>
                </section>
            </Animate>
            <div style={{maxWidth: 300, marginBottom: 30}}>
                <SearchForm />
            </div>
            <div style={{marginBottom: 30}}>
                <table className={TableStyle.table}>
                    <thead style={{background: '#DBDDE8 0% 0% no-repeat padding-box', borderRadius: '8px 8px 0px 0px', color: '#1A1A1A', fontSize: '12px'}}>
                    <tr>
                        <th>ID</th>
                        <th>Address</th>
                        <th>Latitude</th>
                        <th>Longitude</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        addresses !== null &&
                        addresses.map((address) => {
                            return (
                                <tr key={address.id}>
                                    <td>{address.id}</td>
                                    <td>{address.address}</td>
                                    <td>{address.coordinates.y}</td>
                                    <td>{address.coordinates.x}</td>
                                    <td><button onClick={() => update(address)}>Update</button></td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </table>
            </div>
            <div id="pagination">
                <Button text="Prev" action={() => pagination(filter, 'prev')}/>
                <span style={{marginRight: 10}}/>
                <Button text="Next" action={() => pagination(filter, 'next')}/>
            </div>
        </main>
    )
}
