/***
*
*   ICON
*   Render an icon from feather icons
*
*   PROPS
*   color: dark/light or hex code
*   image: image to use (see: https://feathericons.com)
*   className: inject a parent class object
*
**********/

import React from 'react';
import ClassNames from 'classnames';
import FeatherIcon from 'feather-icons-react';
import Style from './icon.module.scss';

export function Icon(props){

  let color;
  const css = ClassNames([

    Style.icon,
    props.className

  ]);

  switch (props.color){

    case 'light':
    color = '#FFFFFF';
    break;

    case 'dark':
    color = '#758197';
    break;

    default:
    color = props.color;
    break;

  }

  return(
    <FeatherIcon
      color={ color }
      icon={ props.image }
      size={ props.size }
      className={ css }
    />
  )
}
