import {Home} from '../frontpage/home/home';

const Routes = [
    {
        path: '/',
        view: Home,
        layout: 'frontpage',
        title: 'Ratency'
    },
];

export default Routes;
