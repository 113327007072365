import React, {useState} from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";
import SkipNextIcon from "../../../assets/icon_skip_next";
import SkipPrevIcon from "../../../assets/icon_skip_prev";

const PAGE_NUMBER_STYLE = 'w-8 flex items-center justify-center cursor-pointer font-bold'

const Pagination = ({ currentPage, onChangePage, pageCount, pagesAroundNumber = 3 }) => {
    const [pageNumberToGo, setPageNumberToGo] = useState();

    const onPrevPage = (evt) => {
        evt.preventDefault();
        if (currentPage !== 1) onChangePage(currentPage - 1);
    };

    const onNextPage = (evt) => {
        evt.preventDefault();
        if (currentPage !== pageCount) onChangePage(currentPage + 1);
    };

    const onPage = ({ page }, evt) => {
        evt.preventDefault();
        onChangePage(page);
    };

    const onGoToPage = ({ page }, evt) => {
        evt.preventDefault();
        onChangePage(page);
    }

    const renderNextLink = () => (
        <li className={`${PAGE_NUMBER_STYLE} bg-white`}>
            <div onClick={onNextPage}>
                <SvgIcon 
                    className="p-3"
                    viewBox="0 0 10 13"
                    style={{height: '13px', width: '10px', boxSizing: 'content-box', stroke: `${currentPage !== pageCount ? "#0a68ff" : "#898989"}`}}>
                    <SkipNextIcon />
                </SvgIcon>
            </div>
        </li>
    );

    const renderPrevLink = () => (
        <li className={`${PAGE_NUMBER_STYLE} rounded-l bg-white`}>
            <div onClick={onPrevPage}>
                <SvgIcon
                    className="p-3"
                    viewBox="0 0 10 13"
                    style={{height: '13px', width: '10px', boxSizing: 'content-box', stroke: `${currentPage !== 1 ? "#0a68ff" : "#898989"}`}}>
                    <SkipPrevIcon />
                </SvgIcon>
            </div>
        </li>
    );

    const renderPageNumbers = () =>
        new Array(pageCount).fill(0).reduce((result, _value, index) => {
            const pageNumber = index + 1;
            const activeClassName = currentPage === pageNumber ? 'bg-blue text-white' : 'bg-white text-blue';

            if (
                pageNumber === 1 ||
                pageNumber === pageCount ||
                Math.abs(currentPage - pageNumber) < pagesAroundNumber
            ) {
                const onPageSelect =
                    pageNumber !== currentPage ? evt => onPage({ page: pageNumber }, evt) : evt => evt.preventDefault();

                // pushing the numbers
                result.push(
                    <li className={`${PAGE_NUMBER_STYLE} ${activeClassName}`} onClick={onPageSelect} key={`page${index}`}>
                        <div>{pageNumber}</div>
                    </li>
                );
            } else if (Math.abs(currentPage - pageNumber) === pagesAroundNumber) {
                // pushing ellipsis
                result.push(
                    <li className={`${PAGE_NUMBER_STYLE} bg-white text-blue`} key={`ellipsis${index}`}>
                        &hellip;
                    </li>
                );
            }

            return result;
        }, []);

    const renderGoTo = () => (
        <div className="w-28 bg-white flex items-center justify-center rounded-r" style={{paddingLeft: "1em", boxSizing: "content-box"}}>
            <div>Go to page</div>
            <form onSubmit={(evt) => onGoToPage({ page: pageNumberToGo }, evt)}>
                <input
                    onChange={(evt) => setPageNumberToGo(evt.target.value !== '' ? Number(evt.target.value) : 1)}
                    className="w-8 h-8 border border-gray rounded ml-3 p-1.5"
                />
            </form>
        </div>
    )

    return (
        <ul className='flex justify-end h-10 mt-8 text-gray-600'>
            {pageCount > 1 && renderPrevLink()}
            {pageCount > 1 && renderPageNumbers()}
            {pageCount > 1 && renderNextLink()}
            {pageCount > 1 && renderGoTo()}
        </ul>
    );
};

export default Pagination;
