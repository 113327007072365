/***
*
*   PRICE PLANS
*   Pricing table for your landing/pricing page
*   Update features.js with your own features
*
**********/

import React from 'react';
import { Card, CheckList, Button, History, usePlans } from 'components/lib';
import Style from './plans.module.scss';

export function PricePlans(props){

  let plans = usePlans();
  const data = plans?.data?.raw;
  const features = require('./features');

  if (!plans.data)
    return false;

  return(
    <section className={ Style.plans }>
      { data?.plans?.map((plan, index) => {

        return (
          <Card shadow rounded
            key={ plan.id }
            className={ Style.plan }>

              <div className={ Style.name }>
                { plan.name }
              </div>

              <div className={ Style.price }>
                { plan?.currency?.symbol }{ plan.price }
                <small>{ plan.interval && '/' }{ plan.interval }</small>
              </div>

              <CheckList items={ features[index] }/>
              <Button text='Sign Up' action={ e => History.push('/signup#' + plan.id.toLowerCase() )}/>

          </Card>
        )
      })}
    </section>
  );
}
