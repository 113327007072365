/***
*
*   PROFILE
*   Update the user profile or close the account
*
**********/

import React, { useContext } from 'react';
import { AuthContext, Form, Card, AccountNav, useAPI } from 'components/lib';
import Style from '../../components/card/card.module.scss';

export function Profile(props){

  // context
  const authContext = useContext(AuthContext);
  // const viewContext = useContext(ViewContext);

  // fetch
  const user = useAPI('/api/user');
  //
  // function closeAccount(){
  //   viewContext.modal.show({
  //
  //     title: 'Close Your Account',
  //     form: closeAccount,
  //     buttonText: 'Close Account',
  //     url: '/api/account',
  //     method: 'DELETE',
  //     text: 'Are you sure you want to delete your account? ' +
  //     'You will lose all of your data and this can not be undone.',
  //
  //   }, () => {
  //
  //     // destory user
  //     localStorage.clear();
  //     History.push('/signup');
  //
  //   });
  // }

  return (
    <main className="container">
      <AccountNav />
      <div style={{display: 'flex'}}>
        <Card
          title='Edit information'
          className={Style.accountCards}
          shadow
          loading={ user.loading }
        >
          { user?.data &&
            <Form
              buttonText='Save'
              url={ authContext.user.permission === 'owner' ? '/api/account' : '/api/user' }
              method='PATCH'
              data={{
                name: {
                  label: 'Name',
                  type: 'text',
                  required: true,
                  value: user.data.name,
                  errorMessage: 'Please enter your name',
                },
                email: {
                  label: 'Email address',
                  type: 'disabled',
                  value: user.data.email,
                }
              }}
              callback={ res => authContext.update({ name: res.data.data.name })}
            />
          }

          {/*{ authContext.permission.owner &&*/}
          {/*  <Fragment>*/}
          {/*    <br/>*/}
          {/*    <Button*/}
          {/*      textOnly*/}
          {/*      action={ closeAccount }*/}
          {/*      text='Close Your Account'*/}
          {/*    />*/}
          {/*  </Fragment>*/}
          {/*}*/}
        </Card>
        <Card
          title='Edit password'
          className={Style.accountCards}
          shadow
        >
          <Form
              url='/api/user/password'
              method='PATCH'
              buttonText='Save Password'
              data={{
                oldpassword: {
                  label: 'Current Password',
                  type: 'password',
                  required: true,
                  placeholder: 'Current Password'
                },
                newpassword: {
                  label: 'New Password',
                  type: 'password',
                  required: true,
                  complexPassword: true,
                  placeholder: 'New Password'
                }
              }}
          />
        </Card>
      </div>
    </main>
  );
}
