import React, { Fragment, useContext } from 'react';
import { AuthContext, History } from 'components/lib';
import { MainRoute } from 'ratency-pwa';
import 'ratency-pwa/lib/styles.css';
import 'flatpickr/dist/themes/light.css';

export function Main(props) {
    // contexts
    const authContext = useContext(AuthContext);
    return (
        <Fragment>
            {authContext?.user &&
               <MainRoute env={process.env.NODE_ENV} history={History} user={authContext.user} />
            }
        </Fragment>
    )
}
