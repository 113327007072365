import Screens2x from './screens@2x.png';
import Screens from './screens.png';
import Style from './home.module.scss';
import CheckIcon from './icon_checkmark.svg';
import RatencyFooter from '../../components/ratencyFooter/ratencyFooter';

export function Home({user}) {
    const year = (new Date()).getFullYear();

    return (
        <div className={Style.page_container}>
            <div className={Style.hero_1_container}>
                <div className="container">
                    <div className={`row`}>
                        <div className="col-12 col-sm-6 no-padding">
                            <div className={Style.hero_1}>
                                <div className={Style.left_side}>
                                    <p className={Style.title}>
                                        <strong>Historical car hire rates</strong>
                                    </p>
                                    <p className={Style.footer}>
                                        Access our database of historical pricing and vehicle availability, sourced directly from car rental companies right across the country
                                    </p>
                                    {user
                                        ? (
                                            <div className={Style.cta_group}>
                                                <a className={Style.cta_primary} href="/app/">Search on Ratency</a>
                                                <a className={Style.cta_secondary} href="/history">My search history</a>
                                            </div>
                                        )
                                        : (
                                            <div className={Style.cta_group}>
                                                <a className={Style.cta_primary} href="/signin">Log in</a>
                                                <a className={Style.cta_secondary} href="/contact">Contact us</a>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`col-12 col-sm-6 no-padding ${Style.home_screens_image}`}>
                            <span>
                                <img
                                    src={Screens}
                                    alt="Ratency"
                                    srcSet={`${Screens}, ${Screens2x} 2x`}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className={`row ${Style.hero_2}`}>
                    <div className="col-12 col-sm-6">
                        <div className={Style.left_side}>
                            <h1>Request a trial of Ratency</h1>
                            <p className={Style.list_title}>Each search includes:</p>
                            <ul>
                                <li>
                                    <img src={CheckIcon} alt="check icon" />
                                    <span>
                                        Access to our database of rates and availability collected from 2017
                                    </span>
                                </li>
                                <li>
                                    <img src={CheckIcon} alt="check icon" />
                                    <span>
                                        Location and radius settings
                                    </span>
                                </li>
                                <li>
                                    <img src={CheckIcon} alt="check icon" />
                                    <span>
                                        Specific vehicle types
                                    </span>
                                </li>
                                <li>
                                    <img src={CheckIcon} alt="check icon" />
                                    <span>
                                        Downloadable reports
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        {/* Intro pricing */}
                        <div className={Style.intro_pricing}>
                            <div className="row">
                                <div className="col-12">
                                    <span>Contact us for a trial</span>
                                    <h3>Take Ratency for a spin and see what it can do!</h3>
                                    <a href="/contact">Contact us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`row ${Style.hero_3}`}>
                    <div className="col-12 col-sm-6 nopadding">
                        <div className={Style.left}>
                            <div className={Style.about_card}>
                                <h1>About us</h1>
                                <p>
                                    Ratency is an innovative database product backed by the leading car rental aggregator in the Southern Hemisphere, VroomVroomVroom.
                                </p>
                                <p>
                                    Processing billions of units of data each year, Ratency aggregates, filters, normalises and stores historical pricing data for future use.
                                </p>
                                <p>
                                    We serve insurers and specialists serving the credit hire claims industry as well as providing aggregate data for trend identification and actuary analysis.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 nopadding">
                        <div className={Style.right}>
                            <div className={Style.contact}>
                                <h1>Contact us</h1>
                                <p>
                                    If you have any questions or require custom reporting solutions, we would love to hear from you.
                                </p>
                                <div className={Style.list}>
                                    <span className={`material-icons-outlined md-blue-circle md-40-circle ${Style.icon}`}>email</span>
                                    <strong>
                                        <a href="mailto:enquiries@ratency.com">enquiries@ratency.com</a>
                                    </strong>
                                </div>
                                <div className={Style.list}>
                                    <span className={`material-icons-outlined md-blue-circle md-40-circle ${Style.icon}`}>room</span>
                                    Level 2, 349 Coronation Drive, Milton, QLD 4064 Australia
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RatencyFooter />
        </div>
    );
}
