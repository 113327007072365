import React, {Fragment} from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";
import RatencyHeader from "../../ratencyHeader/ratencyHeader";
import BlueSquiggle from "../../../assets/blue_squiggle";
import './errorPage.scss';

export function ErrorPageLayout(props){
    return (
        <Fragment>
            <RatencyHeader/>
            <main className="error-page-body flex z-10 relative items-center">
                { <props.children {...props.data }/> }
            </main>
            <SvgIcon
                className="absolute top-0 right-0 z-0"
                viewBox="0 0 1366 768"
                style={{height: '768px', width: '1366px'}}
            >
                <BlueSquiggle />
            </SvgIcon>
        </Fragment>
    );
}
