/***
*
*   APP LAYOUT
*   Main application layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   title: title of the view
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, AppNav, Header, HoverNav, Button } from 'components/lib';
import ClassNames from 'classnames';
import Style from './app.module.scss';
import '../layout.scss'; // globals

export function AppLayout(props){

  // context & style
  const context = useContext(AuthContext);
  const css = ClassNames([ Style.app, 'with-sidebar' ]);

  return (
    <Fragment>
      <AppNav
        type='popup'
        items={[
          { label: 'Ratency', icon: 'monitor', link: '/app/' },
          { label: 'Dashboard', icon: 'activity', link: '/dashboard' },
          { label: 'Depot', icon: 'package', link: '/depot', },
          { label: 'Account', icon: 'user', link: '/account', },
          { label: 'Help', icon: 'help-circle', link: '/help' }
        ]}
      />

      <main className={ css }>

        <Header title={ props.title }>
          <HoverNav icon='user' label={ context.user.name } align='right' dark>
            <Button text='Account Settings' action={ e => window.location = '/account' }/>
            <Button text='Signout' action={ context.signout }/>
          </HoverNav>
        </Header>

        { <props.children {...props.data }/> }

      </main>
    </Fragment>
  );
}
