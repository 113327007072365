import React, {useContext} from "react";
import {NavLink} from "react-router-dom";
import {Avatar, makeStyles} from "@material-ui/core";
import logo from "../../assets/ratency-logo.svg";
import ArrowDown from "../../assets/arrow_down";
import SvgIcon from "@material-ui/core/SvgIcon";
import {Button} from "../button/button";
import {HoverNav} from "../nav/hover/hover";
import {AuthContext} from "../../app/auth";
import './ratencyHeader.scss';
import {History} from "../lib";

const useStyles = makeStyles(() => ({
    avatar: {
        width: '33px',
        height: '33px',
        color: '#FFFFFF',
        backgroundColor: '#F88D15',
        fontSize: '20px',
    }
}));

const RatencyHeader = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const context = useContext(AuthContext);
    const classes = useStyles();

    const handleLogoClick = () => {
        History.push('/')
    }

    return (
        <div className="header-container container">
            <img src={logo} alt="logo" style={{width: '156px', height: '32px', cursor: 'pointer'}} onClick={handleLogoClick}/>
            {user ? (
                <div className="flex justify-between items-center text-base text-gray-500 w-1/2 min-w-450">
                    <NavLink to={'/app/'} className="link" activeClassName="active-link">Search</NavLink>
                    <NavLink to={'/history'} className="link" activeClassName="active-link">History</NavLink>
                    <NavLink to={'/contact'} className="link" activeClassName="active-link">Contact us</NavLink>
                    <NavLink to={'/account'} className="link" activeClassName="active-link">My account</NavLink>
                    <span className="avatar">
                        <Avatar alt={user.name} className={classes.avatar}>
                            {user.name.slice(0, 1).toUpperCase()}
                        </Avatar>
                        <HoverNav
                            label={
                                <SvgIcon className="ml-2 fill-gray-500 cursor-pointer">
                                    <ArrowDown/>
                                </SvgIcon>
                            }
                            align="right"
                        >
                            <Button text='Log out' action={context.signout}/>
                          </HoverNav>
                    </span>
                </div>
            )
            : <div/>
            }
        </div>
    )
}

export default RatencyHeader
