import { Main } from 'views/ratency/main';

const Routes = [
    {
        path: '/app/*',
        view: Main,
        layout: 'ratency',
        permission: 'user',
        title: 'Ratency App'
    }
]

export default Routes;
