import React, {Fragment, useState, useEffect} from 'react';
import {CircularProgress, LinearProgress, withStyles} from '@material-ui/core';
import RatencyHeader from "../../ratencyHeader/ratencyHeader";
import HistoryTable from "./historyTable";
import {Link, Search, useAPI} from "../../lib";
import Style from "../../table/table.module.scss";
import {addDays, intervalToDuration, isBefore} from "date-fns";

const PLAN_SEARCHES_COUNT = 200;
const columnsForSearch = ['created_at', 'name', 'email', 'parameters', 'state'];

export function HistoryPageLayout(props){
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user) {
        window.location = '/signin'
    }

    const hardLimits = useAPI('/api/account');
    const {data, loading} = useAPI('/api/search/')
    const [filter, setFilter] = useState()
    const [selectedUser, setSelectedUser] = useState('all');

    const resultFilter = (data) => {
        if (selectedUser === 'all') {
            return data;
        }

        return data.filter((result) => result.name === user.name);
    }

    const handleSelectUser = (event) => {
        setSelectedUser(event.target.value);
    }

    const handleSearch = (searchString) => {
        // search string in columnsForSearch in each row &
        // update state to show only filtered rows
        let rowsToShow = [];

        data.forEach(row => {
            for (let cell in row) {
                if (columnsForSearch.includes(cell)) {
                    if (row[cell].toString().toLowerCase().includes(searchString.toLowerCase())) {
                        if (!rowsToShow.includes(row))
                            rowsToShow.push(row);
                    }
                    if (cell === 'parameters') {
                        for (let param in row[cell]) {
                            if (row[cell][param].toString().toLowerCase().includes(searchString.toLowerCase())) {
                                if (!rowsToShow.includes(row))
                                    rowsToShow.push(row);
                            }
                        }
                    }
                    if (cell === 'state') {
                        const expDate = addDays(new Date(row.created_at), 2);
                        const todayDate = new Date();

                        const expTime = intervalToDuration({
                            start: expDate,
                            end: todayDate
                        })
                        let value;
                        if (row[cell] < 6) {
                            value = 'Pending'
                        } else {
                            if (expTime.days < 2 && isBefore(todayDate, expDate)) {
                                value = 'Active';
                            } else {
                                value = 'Expired'
                            }
                        }
                        if (value.toString().toLowerCase().includes(searchString.toLowerCase())) {
                            if (!rowsToShow.includes(row)) {
                                rowsToShow.push(row);
                            }
                        }
                    }
                }
            }
        })

        setFilter(rowsToShow);
    }

    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            height: 10,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: '#898989',
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#0b68ff',
        },
    }))(LinearProgress);

    const renderHeader = () => (
        <div className="flex justify-between mb-3">
            <div className="text-2xl text-black-400 font-bold">Search history</div>
            <div className="flex flex-col w-3/12 items-end">
                <span className="w-full">
                    <BorderLinearProgress variant="determinate" value={hardLimits?.data && hardLimits.data.monthly_searches <= hardLimits.data.hard_limit ? hardLimits.data.monthly_searches*100/hardLimits.data.hard_limit : 100}/>
                </span>
                <span className="flex justify-end mt-4 text-xs font-bold text-gray-700">
                    <span>{`${hardLimits?.data && hardLimits.data.monthly_searches <= hardLimits.data.hard_limit ? hardLimits.data.hard_limit - hardLimits.data.monthly_searches : 0} searches left.`}</span>
                    &nbsp;
                    { 
                        (
                            user.permission === 'master' ||
                            user.permission === 'owner'
                        ) &&
                        <Link url="/account/billing" text="Need more?" className="text-blue underline cursor-pointer" />
                    }
                </span>
            </div>
        </div>
    )

    return (
        <Fragment>
            <RatencyHeader/>
            <main className="mx-auto container">
                <div>
                    {renderHeader()}
                    <div className={ Style.search_container }>
                        <Search className={ Style.search } callback={ handleSearch }/>
                        {
                            (
                                user.permission === 'master' ||
                                user.permission === 'owner'
                            ) &&
                            <div className={ Style.owners_filter }>
                                <select onChange={handleSelectUser}>
                                    <option value="all"> Show all searches</option>
                                    <option value="currentUser"> Show only my search</option>
                                </select>
                                <span className="focus" />
                            </div>
                        }
                    </div>
                    {loading
                        ? (
                            <div className="flex h-36 justify-center items-end">
                                <CircularProgress/>
                            </div>
                        )
                        : <HistoryTable data={resultFilter(filter ? filter : data)}/>
                    }
                </div>
            </main>
        </Fragment>
    );
}
