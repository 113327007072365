/***
*
*   SIGN IN
*   Sign in form for all account types (including master).
*
**********/

import React, { useContext, useState } from 'react';
import {AuthContext, Button, Form, Link, Message} from 'components/lib';
import RatencyHeader from "../../components/ratencyHeader/ratencyHeader";
import Style from "./auth.module.scss";

export function Signin(props){
  const context = useContext(AuthContext);
  const [useMagic, setUseMagic] = useState(false);
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [form, setForm] = useState({
    email: {
      label: 'Email',
      type: 'email',
      placeholder: 'Your email',
      required: true,
    },
    password: {
      label: 'Password',
      type: 'password',
      placeholder: 'Password',
      required: true,
    },
    forgotpassword: {
      type: 'link',
      url: '/forgotpassword',
      text: 'Forgot your password?'
    }
  });


  function toggleMagicLink(useMagic){
    const f = {...form };
    f.password.type = useMagic ? 'hidden' : 'password';
    f.password.required = useMagic ? false : true;
    f.forgotpassword.type = useMagic ? 'hidden' : 'link';

    setForm(f);
    setUseMagic(useMagic);
  }

  return(
      <div className={Style.page_white_background}>
        <RatencyHeader/>
        <div className="py-lg-1 mx-auto flex flex-col container">
          <div className={Style.header}>Log in to Ratency</div>
          { magicLinkSent ?
            <div style={{ width: '28em', margin: '0 auto' }}>
              <Message
                title='Check Your Email'
                type='success'
                text='Please click the link in your email inbox to sign in.'
              />
            </div> :
            <div style={{maxWidth: '364px'}}>
              <div style={{margin: '16px 0 40px'}}>
                { useMagic ?
                  <Button
                    text='Use password'
                    color='blue'
                    icon='shield'
                    iconColor='white'
                    className='text-sm'
                    iconButton
                    action={ () => { toggleMagicLink(false) }}
                  /> :
                  <Button
                    text='Use Magic Link instead'
                    color='blue'
                    icon='star'
                    iconColor='white'
                    className='text-sm'
                    iconButton
                    action={ () => { toggleMagicLink(true) }}
                   />
                }
              </div>
              <Form
                data={ form }
                method='POST'
                url={ useMagic ? '/api/user/auth/magic' : '/api/user/auth' }
                buttonText={ useMagic ? 'Send Magic Link' : 'Log in' }
                callback={ (res) => {
                  useMagic ? setMagicLinkSent(true) : context.signin(res);
                }}
              />
            </div>
         }
      </div>
    </div>
  );
}
