/***
*
*   MISSIONCONTROL LAYOUT
*   The master dashboard layout containing the navigation
*   and header (title, secondary nav)
*
*   PROPS
*   title: title of the view
*
**********/

import React, { Fragment, useContext } from 'react';
import ClassNames from 'classnames';
import { AuthContext, AppNav, Header, HoverNav, Button } from 'components/lib';
import Style from '../app/app.module.scss';

export function MissionControlLayout(props){

  // context
  const context = useContext(AuthContext);

  // style
  const css = ClassNames([ Style.app, 'with-sidebar' ]);

  return (
    <Fragment>
      <AppNav
        type='popup'
        items={[
          { label: 'Ratency', icon: 'monitor', link: '/app/' },
          { label: 'Dashboard', icon: 'activity', link: '/missioncontrol/dashboard' },
          { label: 'Feedback', icon: 'heart', link: '/missioncontrol/feedback' },
          { label: 'Accounts', icon: 'credit-card', link: '/missioncontrol/accounts' },
          { label: 'Users', icon: 'users', link: '/missioncontrol/users' }
        ]}
      />

      <main className={ css }>

        <Header title={ props.title }>
          <HoverNav label={ context.user.name } align='right' dark>
            <Button text='Signout' action={ context.signout }/>
          </HoverNav>
        </Header>

        { <props.children {...props.data }/> }

      </main>
    </Fragment>
  );
}
