import React from 'react';
import { Button } from 'components/lib';
import './404.scss'

export function NotFound(props) {

  return(
    <div className="container error-page-container">
      <h1>404</h1>
      <div className="message">Oops, the page you are looking for does not exist.</div>
      <Button className="btn-home" text='Go home' action={ e => window.location = '/' }/>
    </div>
  );
}
