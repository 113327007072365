/**
 * Header for front page, we show ratency logo
 */
import logo from '../../assets/ratency-logo.svg';
import Style from './header.module.scss';

export function Header({user}) {
   return (
       <div className={Style.header_container}>
           <div className="container">
               <div className={Style.header}>
                   <img src={logo} alt="logo" className={Style.logo_img} />
                   {user
                       ? <a className={Style.cta_primary} href="/app/">Search Ratency ></a>
                       : (
                           <div className={Style.cta_group}>
                               <a className={Style.cta_primary} href="/signin">Log in</a>
                           </div>
                       )
                   }
               </div>
           </div>
       </div>
   );
}
