import React, {Fragment} from 'react';
import RatencyHeader from "../../ratencyHeader/ratencyHeader";
import RatencyFooter from '../../ratencyFooter/ratencyFooter';
import './ratency.scss';

export function RatencyLayout(props){
    return (
        <Fragment>
            <RatencyHeader/>
            <main className="ratency-body">
                { <props.children {...props.data }/> }
            </main>
            <RatencyFooter />
        </Fragment>
    );
}
