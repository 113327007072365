/***
*
*   ONBOARDING
*   Example of onboarding flow*
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, Onboarding, Form, Image } from 'components/lib';
import Astronaut from './images/astronaut.jpg';

export function OnboardingView(props){

  const context = useContext(AuthContext);

  return (
    <Onboarding 
      save
      onFinish='/dashboard'
      views={[
      {
        name: 'Getting Started',
        description: `Welcome, ${context.user.name}!`,
        component: <Welcome/>
      },
      { 
        name: 'Invite your team', 
        description: 'Gravity is more fun when you invite your team.',
        component: <InviteUsers/>,
      }
    ]}/>
  )
}

function Welcome(){

  return (
    <Fragment>

      <p>This is an example of the user-onboarding flow.
        You can replace this with your own content, try out the action
        in the next step or skip the intro entirely.</p>
        
      <p>Have fun playing with Gravity!</p>

      <Image source={ Astronaut }/> 

    </Fragment>
  )
}

function InviteUsers(props){

  return (
    <Form 
      data={{
        email: {
          label: 'Email',
          type: 'email',
          required: true,
        }
      }}
      buttonText='Send Invite'
      url='/api/invite'
      method='POST'
    />
  )
}