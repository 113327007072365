/***
*
*   SIGN UP (user)
*   Signup form for child users
*
**********/

import React, { useContext } from 'react';
import { Animate, Row, AuthContext, Card, Form } from 'components/lib';
import RatencyHeader from "../../components/ratencyHeader/ratencyHeader";
import Style from "./auth.module.scss";

export function SignupUser(props){

  const context = useContext(AuthContext);
  const url = window.location.href;
  const id = url.substring(url.indexOf('?id=')+4, url.indexOf('&email'));
  const email = url.substring(url.indexOf('&email')+7);

  return(
    <div className={Style.page_white_background}>
      <RatencyHeader/>
      <div className="container">
        <Animate type="pop">
          <div className={Style.header}>Create an account</div>
          <Card noPadding className={Style.card_container}>
            <Form
              data={{
                name: {
                  label: 'Name',
                  placeholder: 'Your name',
                  value: '',
                  type: 'text',
                  required: true,
                  errorMessage: 'Please enter your first name'
                },
                email: {
                  label: 'Email',
                  placeholder: 'Your email',
                  value: email,
                  type: 'email',
                  required: true,
                },
                password: {
                  label: 'Password',
                  placeholder: 'Password',
                  type: 'password',
                  required: true,
                  complexPassword: true,
                },
                confirm_password: {
                  type: 'hidden',
                  value: null,
                },
                inviteId: {
                  type: 'hidden',
                  value: id
                },
              }}
              url='/api/user'
              method='POST'
              redirect='/dashboard'
              buttonText='Create account'
              callback={ context.signin }
            />

            <span className={Style.alternative_message}>Already have account? <a href='/signin'>Log In</a></span>

          </Card>
        </Animate>
      </div>
    </div>
  );
}
