/***
*
*   SIGN UP
*   Signup form for account owners
*
**********/

import React, {useContext} from 'react';
import { Animate, AuthContext, Card, Form }  from 'components/lib';
import RatencyHeader from "../../components/ratencyHeader/ratencyHeader";
import Style from "./auth.module.scss";

export function Signup(props){
  const context = useContext(AuthContext);

  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.permission !== 'master') {
      window.location = '/app/'
  }

  const firstStepFields = {
      name: {
          label: 'Name',
          type: 'text',
          required: true,
          placeholder: 'Your full name',
          errorMessage: 'Please enter your first name'
      },
      email: {
          label: 'Email',
          type: 'email',
          placeholder: 'Enter email address',
          required: true,
      },
      phone: {
          label: 'Contact no.',
          type: 'phone',
          placeholder: 'Where can we contact you?',
          required: true,
      },
      password: {
          label: 'Password',
          type: 'password',
          required: true,
          placeholder: 'Choose a password',
          complexPassword: true
      },
      plan: {
          type: 'hidden',
          default: 'free',
      },
      companyName: {
          label: 'Company Name',
          type: 'text',
          required: false,
          placeholder: 'Your company name'
      },
      position: {
          label: 'Position',
          type: 'text',
          required: false,
          placeholder: 'Your position'
      },
  }

  return(
    <div className={Style.full_height}>
      <RatencyHeader/>
      <div className="container">
        <Animate type="pop">
          <div className={Style.header}>Sign Up</div>
          <Card noPadding className={Style.card_container}>
            <Form
              updateOnChange
              data={firstStepFields}
              url='/api/account'
              method='POST'
              fullWidthButton={false}
              buttonText={'Submit'}
              callback={ context.signin }
            />
          </Card>
        </Animate>
      </div>
    </div>
  );
}
