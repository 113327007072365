/***
*
*   AUTH LAYOUT
*   Layout for the signup/signin pages
*
**********/

import React from 'react';
import Style from './auth.module.scss';

export function AuthLayout(props){

  return (
    <main className={ Style.auth }>
      { <props.children {...props.data }/> }
    </main>
  );
}
