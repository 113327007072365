import React from 'react';
import { Button, Badge } from 'components/lib';
import Style from './table.module.scss';
import ClassNames from 'classnames';

export function Body(props){

  if (props.data?.length){
    return (
      <tbody>
        { props.data.map((row, index) => {
          return (
            <Row
              contentFormatter={props.contentFormatter}
              key={ index }
              data={ row }
              show={ props.show }
              hide={ props.hide }
              badge={ props.badge }
              actions={ props.actions }
              useTextActions={props.useTextActions}
            />
          );
      })}
      </tbody>
    );
  }

  return (
    <tbody>
      <tr>
        <td colSpan='10'>No results found</td>
      </tr>
    </tbody>
  );
}

export function Row(props){

  let row = {...props.data }
  row.actions = props.actions;
  const useTextActions = props.useTextActions;
  const hasActions = Object.values(row.actions).some(x => (x !== undefined));

  const renderValue = (value, cell) => {
    if (
        typeof props.contentFormatter !== 'undefined' &&
        typeof props.contentFormatter[cell] !== 'undefined'
    ) {
      return props.contentFormatter[cell](value);
    }

    return value;
  }

  return(
    <tr data-id={ props.data.id }>
      { Object.keys(row).map((cell, index) => {

        // actions
        if (cell === 'actions'){
          if (hasActions){
            return (
              <td key={ index } className={ Style.actions }>
              { row.actions?.custom?.map((action, i) => {

                 return <Button key={ i } icon={ action.icon } action={ () => action.action(row) } />

                })}
                { row.actions.delete && useTextActions === true &&
                  <Button action={ () => row.actions.delete(row) } textOnly text="Delete" className="font-bold delete-button text-underline" /> }
                {
                  row.actions.edit && useTextActions === true &&
                  <Button action={() => row.actions.edit(row)} textOnly text="Edit" className="font-bold edit-button text-underline" />
                }
                { row.actions.edit && useTextActions !== true &&
                  <Button icon='edit' action={ () => row.actions.edit(row) }/> }
                { row.actions.delete && useTextActions !== true &&
                  <Button icon='trash' action={ () => row.actions.delete(row) } /> }
                  
                { row.actions.email &&
                  <Button icon='mail' action={ () => window.location = `mailto:${row.email}` }/>}
              </td>
            );
          }
          else return false;

        }

        // hide
        if (props.hide?.includes(cell))
          return false;

        // show
        if (props.show && !props.show.includes(cell))
          return false;

        let value = row[cell];

        // is date
        if (/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(value))
          value = new Date(value).toISOString().split('T')[0];

        // has badge
        if (props.badge && cell === props.badge.col && value){
          return (
            <td key={ index }>
              <Badge text={ value } color={ props.badge.color}/>
            </td>
          );
        }

        // standard cell
        return(
          <td key={ index }>
            { value === true  ? 'Yes' : (value === false ? 'No' : renderValue(value, cell)) }
          </td>
        );
      })}
    </tr>
  );
}
