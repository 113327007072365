import React, {useState, useCallback, useEffect} from 'react';
import ClassNames from 'classnames';
import { orderBy as _orderBy } from 'lodash';
import Pagination from "./pagination";
import ArrowDown from "../../../assets/arrow_down";
import Style from './history.module.scss';
import {Link} from "../../lib";
import {addMonths, format, intervalToDuration, isBefore} from "date-fns";

const MAX_RECORDS_ON_PAGE = 20;

const HistoryTable = ({data}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [sortedData, setSortedData] = useState(data);
    const [sortedBy, setSortedBy] = useState('created_at');
    const [order, setOrder] = useState('desc');
    const [dataToDisplay, setDataToDisplay] = useState(sortedData?.slice(0, MAX_RECORDS_ON_PAGE));

    const getPageData = useCallback((fullList) => {
        const startIndex = data === null || data.length < MAX_RECORDS_ON_PAGE ? 0 : (currentPage - 1) * MAX_RECORDS_ON_PAGE;
        return fullList.slice(startIndex, startIndex + MAX_RECORDS_ON_PAGE)
    }, [currentPage, data]);

    const pageCount = sortedData ? Math.ceil(sortedData.length / MAX_RECORDS_ON_PAGE) : 0;

    const handleSort = useCallback((columnName, updateOrder) => {
        const newOrder = sortedBy === columnName ? (order === 'asc' ? 'desc' : 'asc') : 'desc';

        updateOrder && setOrder(newOrder);
        setSortedBy(columnName);

        let sortBy = [columnName];
        let orderToSort = updateOrder ? [newOrder] : [order];

        if (columnName === 'state') {
            sortBy = [
                (resultItem) => {
                    const expDate = addMonths(new Date(resultItem.created_at), 3);
                    const todayDate = new Date();

                    const expTime = intervalToDuration({
                        start: expDate,
                        end: todayDate
                    })
                    return resultItem.state === 6 && expTime.months < 3 && isBefore(todayDate, expDate)
                },
                columnName
            ]
        }

        const sortedData = _orderBy(
            data,
            sortBy,
            orderToSort
        );
        setSortedData(sortedData);
        setDataToDisplay(getPageData(sortedData))
    }, [sortedBy, data, setSortedData, order, getPageData]);

    useEffect(() => {handleSort(sortedBy, false)},[data, sortedBy, handleSort])

    const handleChangePage = (page) => {
        if (page > pageCount) {
            const newStartIndex = pageCount * MAX_RECORDS_ON_PAGE
            setDataToDisplay(sortedData.slice(newStartIndex, newStartIndex + MAX_RECORDS_ON_PAGE));
        } else if (page > currentPage) {
            const newStartIndex = currentPage * MAX_RECORDS_ON_PAGE
            setDataToDisplay(sortedData.slice(newStartIndex, newStartIndex + MAX_RECORDS_ON_PAGE))
        } else {
            const newStartIndex = currentPage * MAX_RECORDS_ON_PAGE - 2 * MAX_RECORDS_ON_PAGE
            setDataToDisplay(sortedData.slice(newStartIndex, newStartIndex + MAX_RECORDS_ON_PAGE))
        }
        setCurrentPage(page > pageCount ? pageCount : page);
    };

    const renderTableHeader = () => {
        const renderHeaderCell = ({columnName, title, subtitle, className}) => {
            const css = ClassNames([
                Style.header_cell,
                className && Style[className]
            ])
            return (
                <div className={css}>
                    <div className="font-bold flex flex-col justify-center text-black-400">
                        {title}
                        <br/>
                        <span className="font-normal">{subtitle}</span>
                    </div>
                    {columnName &&
                    <span onClick={() => handleSort(columnName, true)}>
                        {order === 'desc' && sortedBy === columnName
                            ? <ArrowDown style={{transform: 'rotate(180deg)'}}/>
                            : <ArrowDown/>
                        }
                    </span>
                    }
                </div>
            );
        }

        return (
            <div className={Style.header_row}>
                {renderHeaderCell({columnName: 'created_at', title: 'Search date'})}
                {renderHeaderCell({columnName: 'name', title: 'User'})}
                {renderHeaderCell({columnName: 'parameters.location', title: 'Location', className: 'location_cell'})}
                {renderHeaderCell({columnName: 'parameters.date', title: 'Start date'})}
                {renderHeaderCell({columnName: 'state', title: 'Status'})}
                {renderHeaderCell({className: 'link_cell'})}
            </div>
        );
    }

    const renderTableBody = () => (
        <>
            <div className={`grid grid-cols-1 grid-rows-${dataToDisplay?.length}`}>
                {dataToDisplay?.map((item, index) => renderTableRow(item, index))}
            </div>
            <Pagination
                currentPage={currentPage}
                onChangePage={handleChangePage}
                pageCount={pageCount}
            />
        </>
    );

    const renderTableRow = (item, index) => {
        const expDate = addMonths(new Date(item.created_at), 3);
        const todayDate = new Date();

        const expTime = intervalToDuration({
            start: expDate,
            end: todayDate
        })

        const renderRowCell = ({value, subValue, className}) => {
            const css = ClassNames([
                Style.table_cell,
                className && Style[className]
            ])
            return (
                <div className={css}>
                    {value}
                    <br/>
                    <span className="font-normal text-gray-700 truncate" title={subValue}>{subValue}</span>
                </div>
            )
        }

        const getReadableStatus = (state) => {
            let value;
            let subValue;
            let className;
            if (state < 6) {
                value = 'Pending'
            } else {
                if (expTime.months < 3 && isBefore(todayDate, expDate)) {
                    value = 'Active';
                    subValue = `Expires in ${expTime.months > 0 ? `${expTime.months}month${expTime.months > 1 ? 's' : ''} ` : ''}${expTime.days > 0 ? `${expTime.days}d ` : ''}${expTime.hours}h ${expTime.minutes}m`;
                    className = 'text-green'
                } else {
                    value = 'Expired'
                }
            }
            return renderRowCell({value, subValue, className})
        }

        return (
            <div
                key={`${item.id}-${index}`}
                className={Style.table_row}
            >
                {renderRowCell({
                    value: format(new Date(item.created_at), 'dd MMM yyy'),
                    subValue: format(new Date(item.created_at), 'hh:mma')
                })}
                {renderRowCell({value: `${item.name}`, subValue: item.email})}
                {renderRowCell({value: item.parameters.location, className: 'location_cell'})}
                {renderRowCell({value: format(new Date(item.parameters.date), 'dd MMM yyyy')})}
                {getReadableStatus(item.state)}
                {renderRowCell({
                    value: expTime.months < 3 && isBefore(todayDate, expDate)
                        ? <Link url={`app/result/${item.reference}`}>View</Link>
                        : <Link
                            url={`app/?${
                                `location=${item.parameters.location}`
                            }${
                                `&latitude=${item.parameters.latitude}`
                            }${
                                `&longitude=${item.parameters.longitude}`
                            }${
                                `&date=${item.parameters.date}`
                            }${
                                item.parameters.vehicleType ? `&vehicleType=${item.parameters.vehicleType}` : ''
                            }`}
                        >
                            Search again
                        </Link>,
                    className: 'link_cell'
                })}
            </div>
        )
    }

    return (
        <div className="text-xs pb-12 text-gray-700">
            <div>{renderTableHeader()}</div>
            <div>{renderTableBody()}</div>
        </div>
    )
}

export default HistoryTable
